import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import React, { useState, useMemo } from "react";
import {
	ConnectionProvider,
	WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
	PhantomWalletAdapter,
	// GlowWalletAdapter,
	// SlopeWalletAdapter,
	SolflareWalletAdapter,
	// TorusWalletAdapter,
	// BackpackWalletAdapter,
	LedgerWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import { HolderAuthentication } from "./components/holder-authentication/HolderAuthentication";
import { SOLANA_RPC_ENDPOINT } from "./values/constants";
import { MetaplexProvider } from "./components/metaplex-connection/MetaplexProvider";
import { UniversalHeader } from "./components/universal-header/UniversalHeader";

// Default styles that can be overridden by your app.
require("@solana/wallet-adapter-react-ui/styles.css");

function App() {
	const network = WalletAdapterNetwork.Mainnet;
	const endpoint = SOLANA_RPC_ENDPOINT.HELIUS;

	const wallets = useMemo(
		() => [
			new PhantomWalletAdapter(),
			// new GlowWalletAdapter(),
			// new SlopeWalletAdapter(),
			new SolflareWalletAdapter({ network }),
			new LedgerWalletAdapter(),
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[network]
	);

	return (
		<ConnectionProvider endpoint={endpoint}>
			<WalletProvider wallets={wallets} autoConnect>
				<WalletModalProvider>
					{/* <MetaplexProvider> */}
						<HolderAuthentication>
							<Router>
								<UniversalHeader />
								<AppRoutes />
							</Router>
						</HolderAuthentication>
					{/* </MetaplexProvider> */}
				</WalletModalProvider>
			</WalletProvider>
		</ConnectionProvider>
	);
}

export default App;
