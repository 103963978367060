import SOLLER_ICON from "../assets/images/header/soller-icon.png";
import SOLOBSERVER_ICON from "../assets/images/header/sol-observer-icon.png";
import KILLSHOT_ICON from "../assets/images/header/killshot-icon.png";
import MARKETWATCH_ICON from "../assets/images/header/alpha-tools-icon.png";

import MBB_SCHOOL_BUS_ICON from "../assets/images/header/mbb-school-bus.png";
import MAP_ICON from "../assets/images/header/map-icon.png";
import COLLAGE_ICON from "../assets/images/header/collage-icon.png";
import LOANS_ICON from "../assets/images/header/graph-lg.png";

export const HOLDERS_DROPDOWN = [
	{ url: "/map", label: "Map View" },
	{
		url: "/profile",
		label: "Profile",
	},
];

export const MONKEY_BABY_DROPDOWN = [
	{ url: "https://www.monkeybaby.business/our-story", label: "Our Story" },
	{ url: "https://rarity.monkeybaby.business/", label: "Marketplace & Rarity" },
	{ url: "https://www.monkeybaby.business/tiers", label: "Membership Tiers" },
	{
		url: "https://www.monkeybaby.business/mbb-supers-showcase",
		label: "MBB Supers",
	},
	{
		url: "https://gitbook.monkeybaby.business/introduction/welcome-to-monkey-baby-business",
		label: "Gitbook",
	},
];

export const TOOLS_DROPDOWN = [
	{
		url: "https://www.soller.ai/",
		label: "Soller.ai",
		icon: SOLLER_ICON,
	},
	{
		url: "https://loans.monkeybaby.business/",
		label: "SOL NFT Loans Tracker",
		icon: LOANS_ICON,
	},
	{
		url: "https://bus.monkeybaby.business/",
		label: "MBB School Bus",
		icon: MBB_SCHOOL_BUS_ICON,
	},

	{
		url: "https://map.monkeybaby.business/login",
		label: "MBB Holders Map",
		icon: MAP_ICON,
		currentSite: true,
	},
	{
		url: "https://collage.monkeybaby.business/",
		label: "Collage Generator",
		icon: COLLAGE_ICON,
	},
];
