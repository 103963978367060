import { getFunctions, httpsCallable } from "firebase/functions";


/** Used to fetch the MBB mint hashlist to know the mint addresses of all MBB NFTs
 *  which is needed to see if a wallet holds a MBB NFT (based on looking at the mint address)
 *  @returns the MBB mint hashlist
 */
export const getMbbHashlist = async () => {
	try {
		const response = await fetch("../../hashlist/mbb-hashlist.json");
		const json = await response.json();
		return json;
	} catch (error) {
		console.log("getMbbHashlist error - ", error);
	}
};

/**
 * Used to verify if a wallet holds a MBB NFT.
 * @returns boolean. t/f whether the wallet is verified to have a MBB NFT
 */
export const verifyMbbHolder = (ownersAddress) => {
	return new Promise(async (resolve, reject) => {
		try {
			// Getting MBB Hashlist
			const mbbHashlist = await getMbbHashlist();

			if (!ownersAddress || !mbbHashlist) resolve(false);

			const allNFTs = await fetchNfts(ownersAddress);

			if (allNFTs == null) resolve(false);

			// Checks if any of the NFTs are MBB NFTs
			const holdingMbb = await holdingMbbNfts(allNFTs, mbbHashlist);

			resolve(holdingMbb);
		} catch (error) {
			console.log("verifyMbbHolder | error", error);
			reject(error);
		}
	});
};

const fetchNfts = async (ownersAddress) => {
	return new Promise(async (resolve, reject) => {
		try {
			let allNFTs = [];

			let interval = setInterval(async () => {

				const onNftsByWalletRequest = httpsCallable(getFunctions(), "onNftsByWalletRequest");
							
				const payload = await onNftsByWalletRequest({
										reqString: "bNP87gUs2zOYdZ0sW4qFRTlLa90",
										wallet: ownersAddress,
									});
				
				allNFTs = payload?.data || [];

				clearInterval(interval);
				resolve(allNFTs);
			}, 3500);


			setTimeout(() => {
				clearInterval(interval);
				resolve(null);
				return;
			}, 15000);

		} catch (error) {
			console.log("holdingMbbNfts | error", error);
			reject(error);
		}
	});
};

/** Iterates through all NFTs to see if any of them are MBB NFTs
 *  based on their mint address.
 *  @returns boolean. t/f whether a wallet has any MBB NFTs.
 */
const holdingMbbNfts = (allNFTs, mbbHashlist) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (mbbHashlist.length > 0 && allNFTs.length > 0) {
				for (let i = 0; i < allNFTs.length; i++) {
					if (
						allNFTs[i]?.mintAddress &&
						mbbHashlist.includes(allNFTs[i]?.mintAddress)
					) {
						resolve(true);
					}
				}
			} else {
				resolve(false);
			}

			resolve(false);
		} catch (error) {
			console.log("holdingMbbNfts | error", error);
			reject(error);
		}
	});
};
